import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Modal } from 'reactstrap'

import scssVars from '~components/scss-variables'

const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  -webkit-appearance: none;
  height: 28px;
  border: none;
  svg {
    width: 22px;
    line {
      transition: stroke 0.25s;
      stroke: ${scssVars.beige};
      stroke-linecap: round;
      stroke-width: 2px;
    }
  }
  &:hover {
    svg line {
      stroke: ${scssVars.brown};
    }
  }
`

export const ReusableModal = ({ className, children, isOpen, toggleModal, showCloseButton, backdropClassName }) => {
  return (
    <div className="text-center">
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className={className}
        backdropClassName={backdropClassName}
        centered
        scrollable
      >
        {showCloseButton && (
          <CloseButton onClick={() => toggleModal(false)}>
            <svg xmlns="http://www.w3.org/2000/svg">
              <g>
                <line x2="20" y2="20" transform="translate(1.414 1.414)"/>
                <line x2="20" y2="20" transform="translate(21.414 1.414) rotate(90)"/>
              </g>
            </svg>
          </CloseButton>
        )}
        {children}
      </Modal>
    </div>
  )
}

ReusableModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  showCloseButton: PropTypes.bool,
  backdropClassName: PropTypes.string
}

ReusableModal.defaultProps = {
  showCloseButton: true,
  backdropClassName: 'modal-backdrop'
}
