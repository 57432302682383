import React, { useState } from 'react'
import { Link }  from 'gatsby'
import { Container, Carousel, CarouselItem, CarouselIndicators } from 'reactstrap'

import styled from 'styled-components'
import scssVars from '~components/scss-variables'

const StyledCarouseIndicators = styled(CarouselIndicators)`
  margin: 0;
  height: 10px;
  bottom: -28px !important;
  li {
    width: 10px !important;
    height: 10px !important;
    opacity: 1;
    background-color: #fff;
    border: 1px solid ${scssVars.darkBrown} !important;
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
    margin: 0 15px 0 0 !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0);
  }
  li:last-of-type {
    margin-right: 0;
  }
  .active {
    background-color: ${scssVars.darkBrown};
  };
`

/* Styles apply starting from mobile and css properties are set as screensize gets bigger */
const StyledCarouselItem = styled(CarouselItem)`
  position: relative;
  opacity: 1;
  min-height: 300px;
`

const CarouselControlContainer = styled.div`
    position: absolute;
    bottom: 6%;
    display: flex;
    align-items: flex-start;
    height: 45px;
    width: 100%;
    z-index: 600;
    .carousel-control-next,
    .carousel-control-prev {
      filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.25));
    }
    .arrow-path {
      fill:none;
      stroke:#fff;
      stroke-linecap:round;
      stroke-linejoin:round;
      stroke-width:2px;
      stroke:white;
    }
    .carousel-control-prev {
      left: 4%;
      width: 30px;
      opacity: 1;
    }
    .carousel-control-next {
      left: 13%;
      width: 30px;
      opacity: 1;
    }
    @media only screen and (min-width: ${scssVars.desktopMin}) {
      .carousel-control-prev {
      left: 2%;
    }
    .carousel-control-next {
      left: 5%;
    }
  }
`

const items = [{
  title: 'Repair & Care Services',
  src: 'https://leatherspa.imgix.net/HomeSlideDesktop1.jpg',
  srcMobile: 'https://leatherspa.imgix.net/HomeSlideMobile1.jpg',
  altText: 'The Art of Leather Care - The premier destination for leather repair and care.',
  path: '/repair-services'
}, {
  title: 'Leather Spa Saks Fifth Ave',
  src: 'https://leatherspa.imgix.net/HomeSlideDesktop2.gif',
  srcMobile: 'https://leatherspa.imgix.net/HomeSlideMobile2.gif',
  altText: 'Leather Spa Saks Fifth Avenue - The experts in leather care at your service at the Saks Fifth Avenue Flagship Store',
  path: '/locations/saks-fifth-avenue'
}, {
  title: 'On-Demand Pick-Up Service',
  src: 'https://leatherspa.imgix.net/HomeSlideDesktop3.jpg',
  srcMobile: 'https://leatherspa.imgix.net/HomeSlideMobile3.jpg',
  altText: 'Leather Spa On-Demand - Pickup, repair and delivery on your schedule. Leather care made easy.',
  path: '/customer-services/pick-up'
}, {
  title: 'Shop Leather Spa Products',
  src: 'https://leatherspa.imgix.net/HomeSlideDesktop4.jpg',
  srcMobile: 'https://leatherspa.imgix.net/HomeSlideMobile4.jpg',
  altText: 'Complete Suite of Products - Superior leather care products developed and used by the leather experts.',
  href: 'https://shop.leatherspa.com'
}]

export const HeroCarousel = () => {
  const [index, setIndex] = useState(0)
  let animating = false
  const onExiting = () => {
    animating = true
  }
  const onExited = () => {
    animating = false
  }
  const next = () => {
    if (animating) return
    const nextIndex = index === items.length - 1 ? 0 : index + 1
    setIndex(nextIndex)
  }
  const previous = () => {
    if (animating) return
    const nextIndex = index === 0 ? items.length - 1 : index - 1
    setIndex(nextIndex)
  }
  const goToIndex = (newIndex) => {
    if (animating) return
    setIndex(newIndex)
  }
  return (
    <section className="pb-5 pt-lg-5">
      <Container className="px-0">
        <Carousel activeIndex={index} next={next} previous={previous}>
          {items.map((item, i) => (
            <StyledCarouselItem onExiting={onExiting} onExited={onExited} key={i}>
              <Link to={item.path ? item.path : item.href} title={item.title}>
                <picture style={{ height: '300px' }}>
                  <source srcSet={item.src} media="(min-width: 992px)"/>
                  <img className="w-100" src={item.srcMobile} alt={item.altText} loading={i === 0 ? 'eager' : 'lazy'} />
                </picture>
              </Link>
            </StyledCarouselItem>
          ))}
          <CarouselControlContainer>
            <a className="carousel-control-prev" role="button" onClick={previous}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(-22240.004 -12677.111) rotate(180)"/></svg>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" role="button" onClick={next}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(22257 12709.11)"/></svg>
              <span className="sr-only">Next</span>
            </a>
          </CarouselControlContainer>
          <StyledCarouseIndicators items={items} activeIndex={index} onClickHandler={goToIndex} />
        </Carousel>
      </Container>
    </section>
  )
}
