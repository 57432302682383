import React from 'react'
import PropTypes from 'prop-types'
import { ModalHeader, ModalBody } from 'reactstrap'
import styled from 'styled-components'

// import scssVars from '~components/scss-variables'

const ModalContent = styled.div`
  padding: 3rem;
  overflow: auto;
`

const Heading = styled(ModalHeader)`
  border-bottom: none !important;
  margin-bottom: 1rem;
  padding: 0 !important;
  h5 {
    font-weight: 600;
    line-height: 1.25;
  }
`

const Body = styled(ModalBody)`
  border-top: none;
  padding: 0 !important;
  p {
    line-height: 1.25;
  }
  ul {
    padding-left: 15px;
    margin-bottom: 2rem;
  }
  li {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 5px;
  }
`

const WelcomeContent = ({ toggleModal }) => (
  <ModalContent>
    <Heading>Welcome to a Fresh, Simpler LeatherSpa.com</Heading>
    <Body>
      <p>We're still working on making this experience better so we'd love to hear your feedback.</p>
      <ul>
        <li>Repair online easier without ever having to leave your home with new services.</li>
        <li>Get your items to us faster with new mail-in and pickup features.</li>
        <li>Find things easily with a cleaner look and bigger text.</li>
      </ul>

      <button className="btn btn-primary w-100" onClick={() => toggleModal(false)}>
        NEXT
      </button>
    </Body>
  </ModalContent>
)

WelcomeContent.propTypes = {
  toggleModal: PropTypes.func
}

export default WelcomeContent
