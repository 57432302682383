import Loadable from "@loadable/component"
import { Link } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { Col, Container, Row } from 'reactstrap'
import styled from 'styled-components'
import '~components/all.scss'
import { BeforeAndAfter } from '~components/Home/BeforeAndAfter'
import { HeroCarousel } from '~components/Home/HeroCarousel'
import { RepairServices } from '~components/Home/RepairServices'
import Layout from '~components/Layout'
import Main from '~components/layouts/Main'
import { ReusableModal } from '~components/Modals/Modal'
import WelcomeContent from '~components/Modals/Welcome'
import scssVars from '~components/scss-variables'
import SEO from '~components/seo'
import { ModalContext } from '~providers/ModalContext'
import stampMobile from '../../static/img/home/love-it-stamp@2x.png'

const Locations = Loadable(_ => import('~components/Home/Locations'))
const InstagramFeed = Loadable(_ => import('~components/Home/InstagramFeed'))

const SectionHeader = styled.h1`
  margin-bottom: 0.5rem;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
  color: ${scssVars.darkBrown};
  @media only screen and (min-width: ${scssVars.tabletMax}) {
    font-size: 2.75rem;
  }
`

const StampSection = styled.section`
margin-top: 80px;
position: relative;
  img {
    position: absolute;
    top: 0;
    width: 180px;
    transform: translate(-50%, -50%);
  }
  h2 {
    font-size: 28px;
    font-weight: 300;
    margin: auto;
    color: $darkBrown;
  }
  p {
    font-size: 18px;
    color: $darkBrown;
    padding: 0 20px;
  }
`

const Lead = styled.p`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
`

const CustomerServiceSection = styled.section`
  ${Lead} {
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: 700;
    color: ${scssVars.darkBrown};
  }
  p {
    line-height: 1.375;
  }
`

const FollowName = styled.h3`
  font-size: 1rem;
  font-weight: bold;
  color: ${scssVars.darkBrown};
  margin-bottom: 0;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    color: ${scssVars.orange}
  }
`

const SectionHeading = styled.h2`
  font-weight: 200;
  color: ${scssVars.darkBrown};
  text-align: center;
  display: block;
  flex-wrap: wrap;
  display: flex;
  font-size: 44px;
  margin-bottom: 20px;
  line-height: 1.25;
  flex-direction: column;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    flex-direction: row;
    text-align: left;
  }
`

const FollowUsContainer = styled(Container)`
  padding: 2.5rem 1.25rem !important;
  ${SectionHeading} {
    a:hover {
      text-decoration: none;
    }
  }
`

const SectionSpan = styled.span`
  border: 1px solid ${scssVars.brown};
  height: 1px;
  margin: 0 auto 40px;
  width: 25%;
  display: block;
  margin: 25px auto;
  height: 1px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: 30px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const SectionContainer = styled(Container)`
  @media only screen and (min-width: ${scssVars.mobileMin}) {
    padding: 1rem 1.25rem !important;
  };
  img {
    width: 100% !important;
  }
`

const StyledContentText = styled.p`
  color: ${scssVars.darkBrown};
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    width: 85%;
  }
`

export default function IndexPage () {
  const { modalOpen, toggleModal } = useContext(ModalContext)
  useEffect(() => {
    const modalLoaded = localStorage.getItem('modal/welcome')
    if (!modalLoaded) {
      localStorage.setItem('modal/welcome', true)
      toggleModal(true)
    }
  })
  return (
    <Layout>
      <SEO pathname="/" title="The Art of Leather Care" />
      <ReusableModal isOpen={modalOpen} toggleModal={toggleModal}>
        <WelcomeContent toggleModal={toggleModal} />
      </ReusableModal>
      <Main>
        <HeroCarousel/>
        <StampSection className="bg-clr-cararra text-clr-sambuca pt-5 pb-5">
          <Container className="text-center mt-5">
            <img src={stampMobile} alt="leather spa stamp" />
            <Row>
              <SectionHeader className="text-uppercase mb-3">
                We take the ordinary and make it extraordinary.
              </SectionHeader>
              <p>
                Leather Spa is the go-to resource for discerning clients seeking the finest repair and care needs for their shoes and other leather accessories for more than 30 years. Be inspired by the array of customized services we offer for all your leather care needs.
              </p>
              <Col className="text-center mt-4" sm={{ size: 12 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }} >
                <Link to="/start-a-repair" className="btn btn-primary px-5">START A REPAIR</Link>
              </Col>
            </Row>
          </Container>
        </StampSection>
        <section className="py-5">
          <Container className="text-center">
            <SectionHeading>
              <span>Repair &amp; Care Services</span>
              <SectionSpan />
            </SectionHeading>
            <RepairServices/>
          </Container>
        </section>
        <BeforeAndAfter />
        <CustomerServiceSection className="py-5">
          <SectionContainer className="text-center">
            <SectionHeading>
              <span>At Your Service</span>
              <SectionSpan />
            </SectionHeading>
            <Row>
              <Col sm={{ size: 12, order: 1 }} md={{ size: 12, order: 1 }} lg={{ size: 8, order: 2 }}>
                <img className="w-100" src="https://leatherspa.imgix.net/Customer-Service_Homepage@2x.png?fm=jpg" alt="TODO"/>
              </Col>
              <Col className="text-lg-left" sm={{ size: 12, order: 2 }} md={{ size: 12, order: 2 }} lg={{ size: 4, order: 1 }}>
                <Lead>
                  Discover all the ways Leather Spa can simplify the repair experience.
                </Lead>
                <StyledContentText>
                  Leather Spa is committed to providing the best customer service experience in addition to offering the finest repair and care for all your leather accessories.
                </StyledContentText>
                <StyledContentText>
                  From pick-up and delivery for our New York City clients, to mail-in orders
                  worldwide, online payment and order status updates, and tips from our experts,
                  discover all the ways Leather Spa can make your repair and care needs easier.
                </StyledContentText>
                <Col className="mt-4 pl-lg-0" sm={{ size: 12 }} md={{ size: 6, offset: 3 }} lg={{ size: 8, offset: 0 }}>
                  <Link to="/customer-services" className="btn btn-primary px-5">READ MORE</Link>
                </Col>
              </Col>
            </Row>
          </SectionContainer>
        </CustomerServiceSection>
        <Locations />
        <section>
          <FollowUsContainer>
            <div className="text-center">
              <SectionHeading>
                Follow Us
                <a to="https://www.instagram.com/leatherspa/" target="_blank" className="d-flex justify-content-center align-items-center ml-lg-3">
                  <FollowName>@LEATHERSPA</FollowName>
                </a>
                <SectionSpan className="d-none d-lg-block" />
              </SectionHeading>
            </div>
            <InstagramFeed />
          </FollowUsContainer>
        </section>
      </Main>
    </Layout>
  )
}
