import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { Row, Col } from 'reactstrap'
import styled from 'styled-components'

import scssVars from '~components/scss-variables'

const Tile = styled(Link)`
  position: relative;
  box-shadow: 1px 1px 2px 0;
  color: #d1d1d1;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    box-shadow: none;
  }
`

const TileHeader = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.05rem;
  color: #2c2010;
  font-style: italic;
  text-decoration: underline;
  text-transform: none;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    line-height: 1.375;
  }
`

export const RepairServices = () => {
  const {
    allMarkdownRemark: { edges }
  } = useStaticQuery(graphql`
    query RepairItemsQuery {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "repair-item-page" } } }, sort: { order: ASC, fields: frontmatter___order }
      ) {
        edges {
          node {
            frontmatter {
              templateKey
              title
              path
              tileDesktop
              tileMobile
            }
          }
        }
      }
    }
  `)
  const items = edges.map((edge) => edge.node.frontmatter)
  return (
    <Row>
      {items.map((item) => (
        <Col className="pb-2" xs={6} lg={3} key={item.title}>
          <Tile className="d-block mb-3" to={item.path} title={item.title}>
            <picture>
              <source srcSet={item.tileDesktop} media={`(min-width: ${scssVars.desktopMin})`} alt={item.title} />
              <img className="w-100" src={item.tileMobile} alt={item.title} />
            </picture>
            <TileHeader className="text-center m-0 py-2">{item.title}</TileHeader>
          </Tile>
        </Col>
      ))}
    </Row>
  )
}
