import React, { useState } from 'react'
import { Container,
  Row,
  Col,
  Carousel,
  CarouselItem
} from 'reactstrap'

import styled from 'styled-components'
import scssVars from '~components/scss-variables'
import beforeAfter2 from '../../../static/img/home/before-after-2@2x.jpg'

import FancyLineHeading from '../LayoutComponents/FancyLineHeading'

const NotFullWidthBackgroundSection = styled.div`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    position: relative;
    width: 100%;
    &:after {
      content: '';
      background-color: white;
      height: 100%;
      position: absolute;
      right: 0;
      width: 100px;
      top: 0;
    }
  }
`

const SectionContainer = styled(Container)`
  padding: 0 1.25rem;
  margin-bottom: 115px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-top: -60px;
    margin-bottom: 70px;
    h1 {
      padding-top: 60px;
    }
  }
`

const StyledFancyLineHeading = styled(FancyLineHeading)`
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding-top: 60px !important;
    &:after {
      content: none;
    }
  }
`

const Paragraph = styled.p`
  font-size: 18px;
  color: ${scssVars.darkBrown};
  padding: 0 25px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    padding: 0;
  };
`

const StyledCarouselWrapper = styled.div`
  margin-bottom: -115px;
  width: 100%;
  height: 80%;
  .slide {
    border: 2px solid #FA8200;
    opacity: 1;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-bottom: -30px !important;
  };
`

const StyledCarouselItem = styled(CarouselItem)`
  position: relative;
  opacity: 1;
  img {
    width: 100%;
  }
  .read-more {
    margin-top: 37px;
    a {
      display: block;
      line-height: 50px;
      padding: 0;
      font-size: 18px;
      color: white;
      font-weight: 500;
    }
  }
  span {
    vertical-align: text-top;
    line-height: 31px;
    font-size: 19px;
    position: absolute;
    color: white;
    font-weight: 100;
  }
`

const CarouselControlContainer = styled.div`
  position: absolute;
  top: 6%;
  display: flex;
  align-self: flex-end;
  height: 45px;
  width: 100%;
  z-index: 600;
  .arrow-path {
    fill:none;
    stroke:#000000;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-width:2px;
  }
  .carousel-control-prev {
    left: 76%;
    width: 30px;
    opacity: 1;
  }
  .carousel-control-next {
    left: 86%;
    width: 30px;
    opacity: 1;
  }
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    top: 70%;
    left: 30%;
  }
`

const items = [{
  src: 'https://leatherspa.imgix.net/before-after-1@2x.png?fit=crop&w=1360&h=936&fm=jpg',
  altText: 'Woman\'s Handbag Before & After'
}, {
  src: beforeAfter2,
  altText: 'Woman\'s Dress Shoes Before & After'
}]

export const BeforeAndAfter = () => {
  const [index, setIndex] = useState(0)
  let animating = false
  const onExiting = () => {
    animating = true
  }
  const onExited = () => {
    animating = false
  }
  const next = () => {
    if (animating) return
    const nextIndex = index === items.length - 1 ? 0 : index + 1
    setIndex(nextIndex)
  }
  const previous = () => {
    if (animating) return
    const nextIndex = index === 0 ? items.length - 1 : index - 1
    setIndex(nextIndex)
  }

  return (
    <NotFullWidthBackgroundSection className="mt-5 pt-5 bg-clr-cararra">
      <SectionContainer className="text-center text-lg-left">
        <Row>
          <Col lg={{ size: 5, order: 2 }}>
            <div style={{ maxWidth: '90%' }}>
              <StyledFancyLineHeading type='h1'>Before & After</StyledFancyLineHeading>
              <Paragraph>
                Our hand-crafted artistry is in a league of its own. View our miraculous transformations below.
              </Paragraph>
            </div>
          </Col>
          <Col md={5} lg={{ size: 6, order: 1 }}>
            <StyledCarouselWrapper>
              <Carousel
                activeIndex={index}
                next={next}
                previous={previous}
              >
                {items.map((item, i) => (
                  <StyledCarouselItem onExiting={onExiting} onExited={onExited} key={i}>
                    <img src={item.src} alt={item.altText} loading={i === 0 ? 'eager' : 'lazy'}/>
                  </StyledCarouselItem>
                ))}
                <CarouselControlContainer>
                  <a className="carousel-control-prev" role="button" onClick={previous}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(-22240.004 -12677.111) rotate(180)"/></svg>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" role="button" onClick={next}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.998" height="31.999" viewBox="0 0 16.998 31.999"><path className="arrow-path" d="M-22256-12708.111l15,15-15,15" transform="translate(22257 12709.11)"/></svg>
                    <span className="sr-only">Next</span>
                  </a>
                </CarouselControlContainer>
              </Carousel>
            </StyledCarouselWrapper>
          </Col>
        </Row>
      </SectionContainer>
    </NotFullWidthBackgroundSection>
  )
}
